<template>
  <div>
    <!-- {{ $store.state.all_members_list }} -->
    <!-- {{ member_income }} -->
    <div class="flex align-center justify-space-between mx-5">
      <v-btn-toggle
        v-model="mode"
        color="accent"
        group
        dense
        class="mt-4"
        mandatory
      >
        <v-btn>
          <icon
            name="list"
            file="feather"
            :class="['is-24x24', { 'accent-text': mode == 0 }]"
          ></icon>
        </v-btn>
        <v-btn>
          <icon
            name="calendar"
            file="feather"
            :class="['is-24x24', { 'accent-text': mode == 1 }]"
          ></icon>
        </v-btn>
      </v-btn-toggle>
      <!-- @added="getData" -->
      <add-finance
        @added="
          getData();
          getMembersIncome();
        "
        :member_income="member_income"
      >
        <template v-slot:add>
          <!-- <v-fab-transition>
            <v-btn color="accent" dark>
              <icon name="plus" file="feather" class="is-24x24"></icon>
            </v-btn>
          </v-fab-transition> -->
        </template>
      </add-finance>
    </div>
    <div
      v-if="mode == 0"
      class="transactions mt-3 mx-5"
      v-touch="{
        left: () => {
          swipeLeft();
        },
        right: () => {
          swipeRight();
        },
      }"
    >
      <!-- tab_item = 'income'; -->
      <v-tabs grow v-model="tab">
        <v-tab @click="tab_item = 0" href="#all">All</v-tab>
        <v-tab @click="tab_item = 1" href="#income">Income</v-tab>
        <v-tab @click="tab_item = 2" href="#expense">Expense</v-tab>
      </v-tabs>

      <div v-if="tab_item == 0">
        <finance-card
          v-for="item in all_data"
          :key="item.id"
          :item="item"
          :hasStatus="true"
        />
        <!-- <income-list /> -->
        <!-- v-for="income in incomes" :key="income.id" :income="income" -->
      </div>
      <div v-if="tab_item == 1">
        <finance-card v-for="item in income_data" :key="item.id" :item="item" />
        <!-- v-for="income in incomes" :key="income.id" :income="income" -->
      </div>
      <div v-if="tab_item == 2">
        <!-- v-for="expense in expenses"
          :key="expense.id"
          :expense="expense" -->
        <finance-card
          v-for="item in expense_data"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
    <!-- <div v-if="mode == 0 && events && events.length" class="mx-5 mt-3">
      <div
        v-for="event in events"
        :key="event.id"
        class="events__card"
        v-touch="{
          left: () => {
            show_delete_icon = true;
            delete_user = event.id;
          },
          right: () => {
            show_delete_icon = false;
            delete_user = null;
          },
        }"
      >
        <div class="flex-between">
          <div class="body-2">{{ event.date_of_event }}</div>
          <div class="caption">{{ event.place }}</div>
        </div>
        <div class="flex-between">
          <div class="events__card--title">{{ event.title }}</div>
          <div v-if="show_delete_icon && delete_user == event.id">
            <icon
              @click.native="deleteEvent(event.id)"
              name="x-circle"
              file="feather"
              class="is-24x24"
            ></icon>
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="mode == 1">
      <div class="mx-5">
        <h2 class="mb-1">Income</h2>
        <member-income-doughtnut />
      </div>
      <div class="ma-5">
        <h2 class="mb-1">Income vs Expense</h2>
        <income-expense :income="income_data" :expense="expense_data" />
      </div>
    </div>
  </div>
</template>

<script>
import MemberIncomeDoughtnut from "@/components/finance/components/member-income-doughtnut.vue";
import IncomeExpense from "@/components/finance/components/income-expense.vue";
import AddFinance from "@/components/finance/components/add-finance.vue";
import FinanceCard from "@/components/finance/components/finance-card.vue";

import {
  financeAllCollection,
  memberIncomeCollection,
  incomeCollection,
  expenseCollection,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  doc,
  db,
  collection,
  getStorage,
  ref,
  getDownloadURL,
  limit,
} from "@/firebaseconfig.js";
var _ = require("lodash");

export default {
  components: {
    "member-income-doughtnut": MemberIncomeDoughtnut,
    "income-expense": IncomeExpense,
    "add-finance": AddFinance,
    "finance-card": FinanceCard,
  },
  data() {
    return {
      mode: 0,
      // tab: null,
      // tab_item: "income",
      tab_item: 0,
      income_data: [],
      expense_data: [],
      all_data: [],
      member_income: null,
      // grouped_events: {},
      // show_delete_icon: false,
      // delete_user: null,
    };
  },
  mounted() {
    this.getData();
    this.getMembersIncome();
    // this.getIncomeData();
    // this.getExpenseData();
  },
  computed: {
    tab() {
      let tab_value = null;
      switch (this.tab_item) {
        case 0:
          tab_value = "all";
          break;
        case 1:
          tab_value = "income";
          break;
        case 2:
          tab_value = "expense";
          break;
        default:
          tab_value = "all";
          break;
      }
      return tab_value;
    },
  },
  methods: {
    swipeLeft() {
      this.tab_item == 2
        ? (this.tab_item = 2)
        : (this.tab_item = this.tab_item + 1);
    },
    swipeRight() {
      this.tab_item == 0
        ? (this.tab_item = 0)
        : (this.tab_item = this.tab_item - 1);
    },
    async getData() {
      this.is_loading = true;
      const q = query(financeAllCollection, orderBy("transaction_date", "asc"));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length) {
        let test = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.all_data = test;
        this.income_data = test.filter((t) => t.type == "income");
        this.expense_data = test.filter((t) => t.type == "expense");
        // this.grouped_events = _.groupBy(this.events, function(t) {
        //   return t.group;
        // });
        this.is_loading = false;
      } else {
        this.is_loading = false;
        console.log("No data");
      }
    },
    async getMembersIncome() {
      const q = query(memberIncomeCollection);
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length) {
        this.member_income = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } else {
        console.log("No data");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

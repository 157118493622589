<template>
  <div class="income__card">
    <div>
      <div class="body-2 flex align-center mb-2">
        <!-- <div
          v-if="hasStatus"
          :class="`is-${item.type}`"
          class="income__card--status mr-2"
        ></div> -->
        <div>{{ item.transaction_date }}</div>
      </div>
      <div class="income__card--title">{{ item.title }}</div>
    </div>
    <div class="text-right">
      <div>{{ item.type == "income" ? item.sent_by : item.paid_by }}</div>
      <div :class="['income__card--amount', `is-${item.type}`]">
        {{ item.amount | currency }}
      </div>
    </div>
    <!-- <div
      v-if="hasStatus"
      :class="`is-${item.type}`"
      class="income__card--status mr-2"
    ></div> -->
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
    hasStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.income {
  margin-bottom: 2rem;
  &__card {
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &--title {
      margin-top: 0.25rem;
      font-size: 1rem;
      font-weight: 500;
      margin-right: 1.25rem;
    }
    &--amount {
      color: $danger;
      font-size: 1.25rem;
      font-weight: 500;
      &.is-income {
        color: $success;
      }
      &.is-expense {
        color: $danger;
      }
    }
    &--status {
      position: absolute;
      height: 100%;
      width: 0.25rem;
      left: 0;
      border-radius: 4px 0 0 4px;
      // height: 8px;
      // width: 8px;
      // border-radius: 50%;
      &.is-income {
        background-color: $success;
      }
      &.is-expense {
        background-color: $danger;
      }
    }
  }
}
</style>

<template>
  <div class="text-center">
    <v-bottom-sheet v-model="add_new_popup" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot name="add">
            <h4 class="show-all">Add</h4>
          </slot>
        </div>
      </template>
      <v-sheet v-if="add_new_popup" class="pa-4" ref="form">
        <!-- {{ paid_by }} -->
        <!-- {{ member_income }} -->
        <!-- {{ selected_paid_by }} -->
        <div class="flex-between pb-8">
          <h3>Add Item</h3>
        </div>
        <div class="add-finance__selector">
          <div
            @click="type = 'income'"
            :class="['btns', type == 'income' ? 'income-btns' : '']"
          >
            Income
          </div>
          <div
            @click="type = 'expense'"
            :class="['btns', type == 'expense' ? 'expense-btns' : '']"
          >
            Expense
          </div>
        </div>

        <!-- ---------------------------------- Date ---------------------------------- -->
        <v-dialog
          ref="finance_modal"
          v-model="finance_modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="transaction_date"
              v-model="transaction_date"
              label="Date"
              readonly
              v-bind="attrs"
              :rules="[rules.required]"
              v-on="on"
              dense
              outlined
              required
              class="mb-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="transaction_date"
            :active-picker.sync="picker"
            :min="new Date(Date.now()).toISOString().substr(0, 10)"
            @change="finance_modal = false"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="finance_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              :disabled="!transaction_date"
              color="primary"
              @click="$refs.finance_modal.save(transaction_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <!-- ---------------------------------- Title --------------------------------- -->
        <v-text-field
          ref="title"
          v-model="title"
          :rules="[rules.required]"
          label="Title"
          dense
          required
          outlined
          class="mb-3"
        ></v-text-field>
        <!-- --------------------------------- Paid by -------------------------------- -->
        <!-- <v-text-field
          ref="paid_by"
          v-model="paid_by"
          :rules="[rules.required]"
          label="Paid By"
          dense
          required
          outlined
          class="mb-3"
        ></v-text-field> -->
        <v-select
          :items="paidby_items.map((u) => u.name)"
          v-model="paid_by"
          :label="type == 'income' ? 'Family Member' : 'Paid By'"
          :rules="[rules.required]"
          dense
          outlined
          class="mb-3"
        ></v-select>
        <!-- --------------------------------- Amount --------------------------------- -->
        <v-text-field
          ref="amount"
          v-model="amount"
          :rules="[rules.required]"
          label="Amount"
          dense
          required
          outlined
          class="mb-3"
          type="number"
        ></v-text-field>
        <!-- ------------------------------- item group ------------------------------- -->
        <v-select
          :items="items"
          v-model="group"
          label="Select Group"
          dense
          outlined
        ></v-select>

        <div class="flex">
          <div
            @click="add_new_popup = false"
            class="kvk-button is-cancel width-50 mr-2"
          >
            Cancel
          </div>
          <div
            @click="addFinance"
            :class="[
              'kvk-button width-50 ml-2',
              type == 'income' ? 'is-income' : 'is-expense',
            ]"
          >
            Add
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {
  db,
  doc,
  getDoc,
  financeAllCollection,
  incomeCollection,
  expenseCollection,
  memberIncomeCollection,
  serverTimestamp,
  addDoc,
  updateDoc,
} from "@/firebaseconfig.js";
export default {
  props: ["member_income"],
  data() {
    return {
      add_new_popup: false,
      title: null,
      amount: null,
      paid_by: null,
      transaction_date: null,
      error: null,
      picker: null,
      date: null,
      group: null,
      finance_modal: false,
      formHasErrors: false,
      type: "income",
      // items: ["Foo", "Bar", "Fizz", "Buzz"],
      rules: {
        required: (value) => !!value || "Required.",
        // counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  mounted() {
    // console.log(new Date().getFullYear());
  },
  computed: {
    form() {
      return {
        transaction_date: this.transaction_date,
        title: this.title,
        amount: this.amount,
        group: this.group,
      };
    },
    items() {
      let currentYear = new Date().getFullYear();
      let value = [currentYear];
      for (let i = 0; i < 4; i++) {
        currentYear++;
        value.push(currentYear);
      }
      return value;
    },
    paidby_items() {
      let list = [];
      this.$store.state.all_members_list.map((user) => {
        list.push({
          id: user.id,
          name: user.name,
        });
      });
      return list;
    },
    selected_paid_by() {
      return this.member_income.filter((u) => u.name == this.paid_by);
    },
  },
  watch: {
    finance_modal(val) {
      val && setTimeout(() => (this.picker = "YEAR"));
    },
  },
  methods: {
    async addFinance() {
      if (this.title !== null) {
        this.is_loading = true;
        let param =
          this.type == "income"
            ? { sent_by: this.paid_by }
            : { paid_by: this.paid_by };

        const docRef = await addDoc(financeAllCollection, {
          added_time: serverTimestamp(),
          added_by_id: this.$store.state.logged_user.uid,
          added_by_name: this.$store.state.logged_user.displayName,
          title: this.title,
          amount: this.amount,
          transaction_date: this.transaction_date,
          type: this.type,
          group: this.group,
          ...param,
        });

        await updateDoc(doc(financeAllCollection, docRef.id), {
          id: docRef.id,
        });
        // {
        //   name:'Prajwal',
        //   total_amount:41234,
        //   user_id:
        //   id:'sdasd',
        //   history:[
        //     {
        //       date:'',
        //       amount:,
        //       title:
        //     }
        //   ]
        // }

        if (
          this.type == "income" &&
          this.selected_paid_by &&
          this.selected_paid_by.length
        ) {
          let history = null;
          let total_amount = 0;
          const docRef = doc(
            db,
            "finance_member_income",
            this.selected_paid_by[0].id
          );
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            total_amount = docSnap.data().total_amount;
            history = docSnap.data().history;
            console.log("Document data:", docSnap.data());
            await updateDoc(doc(memberIncomeCollection, docRef.id), {
              total_amount: parseFloat(total_amount) + parseFloat(this.amount),
              history: [
                {
                  transaction_date: this.transaction_date,
                  title: this.title,
                  amount: parseFloat(this.amount),
                },
                ...history,
              ],
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        } else {
          await addDoc(memberIncomeCollection, {
            name: this.paid_by,
            total_amount: parseFloat(this.amount),
            transaction_date: this.transaction_date,
            history: [
              {
                transaction_date: this.transaction_date,
                title: this.title,
                amount: parseFloat(this.amount),
              },
            ],
          });
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }

        this.add_new_popup = false;
        this.$emit("added");
        this.is_loading = false;
        this.title = null;
        this.amount = null;
        this.type = "income";
        this.paid_by = null;
        this.group = null;
        this.transaction_date = null;
        this.formHasErrors = false;
      } else {
        console.log("has error");
        this.formHasErrors = false;
        Object.keys(this.form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true;

          this.$refs[f].validate(true);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-finance {
  &__selector {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
    margin-bottom: 1.75rem;
    .btns {
      height: 2rem;
      width: 100%;
      background-color: $white;
      border: 1px solid #b6b6b6;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.income-btns {
        transition: all 0.3s ease-in-out;
        color: $white;
        background-color: $primary;
        border: 1px solid $primary;
      }
      &.expense-btns {
        transition: all 0.3s ease-in-out;
        color: $white;
        background-color: $danger;
        border: 1px solid $danger;
      }
    }
  }
}
</style>

<template>
  <div class="donut-card">
    <canvas id="incomeexpense" width="300" height="300"></canvas>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
export default {
  extends: Doughnut,
  props: {
    income: {
      type: Array,
      default: [],
    },
    expense: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      member_income: [{ id: "", name: "", amount: "" }],
      chart_config: {
        type: "bar",
        data: {
          // labels: ["1900", "1950", "1999", "2050"],
          labels: [],
          datasets: [
            {
              label: "Income",
              backgroundColor: "#43AA8B",
              // data: [133, 221, 783, 2478],
              data: [],
            },
            {
              label: "Expense",
              backgroundColor: "#F94144",
              // data: [408, 547, 675, 734],
              data: [],
            },
          ],
        },
        options: {
          title: {
            display: false,
            text: "Population growth (millions)",
          },
        },
      },
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      var ctx = document.getElementById("incomeexpense").getContext("2d");

      let grouped_income = _.groupBy(this.income, function(t) {
        return t.group;
      });
      let grouped_expense = _.groupBy(this.expense, function(t) {
        return t.group;
      });

      // grouped_income.map(()=>{

      // })
      // console.log("asdasd", incomeexpense.data.labels);
      for (const [key, value] of Object.entries(grouped_income)) {
        this.chart_config.data.labels.push(key);
        let total_income = 0;
        value.forEach((v) => {
          if (v.amount != null) {
            total_income = total_income + parseFloat(v.amount);
          }
        });
        this.chart_config.data.datasets[0].data.push(total_income);
      }

      for (const [key, value] of Object.entries(grouped_expense)) {
        this.chart_config.data.labels.push(key);
        let total_expense = 0;
        value.forEach((v) => {
          if (v.amount != null) {
            total_expense = total_expense + parseFloat(v.amount);
          }
        });
        this.chart_config.data.datasets[1].data.push(total_expense);
      }

      // for (const [key, value] of Object.entries(grouped_expense)) {
      //   if (!this.chart_config.data.labels.includes(key)) {
      //     this.chart_config.data.labels.push(key);
      //   }
      // }

      var incomeexpense = new Chart(ctx, this.chart_config);

      console.log("dasd", incomeexpense.data.labels);
      // console.log("dasd", grouped_income);
    },
  },
};
</script>

<style lang="scss" scoped>
.donut-card {
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #fff;
}
</style>

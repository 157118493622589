<template>
  <div class="donut-card">
    <canvas id="myChart" width="300" height="300"></canvas>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
export default {
  extends: Doughnut,
  data() {
    return {
      member_income: [{ id: "", name: "", amount: "" }],
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      var ctx = document.getElementById("myChart").getContext("2d");
      var myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["A", "B", "C", "D", "E", "F", "G", "H", "I"],
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: [
                "#f94144",
                "#f3722c",
                "#f8961e",
                "#f9844a",
                "#f9c74f",
                "#90be6d",
                "#43aa8b",
                "#4d908e",
                "#577590",
              ],
              data: [
                25000,
                25000,
                25000,
                25000,
                25000,
                25000,
                25000,
                25000,
                25000,
              ],
            },
          ],
        },
        options: {
          title: {
            display: false,
            text: "Predicted world population (millions) in 2050",
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.donut-card {
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #fff;
}
</style>
